import React from 'react'

export default function InSeasonView() {
  return (
    <div>
      <p>
        Blossom blight risk predictions begin at{' '}
        <a
          href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/Notes-on-first-blossom-open-biofix-20201221.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          first blossom open
        </a>
        . If bloom in your orchard has not yet occurred, continue to check fire
        blight risk predictions and monitor bloom daily. Infection cannot occur
        without open blossoms.
      </p>

      <p>
        Most serious fire blight epidemics begin with infection during bloom.
        Certain antibiotics can effectively protect against blossom infections
        when applied shortly before or immediately after they occur. The
        Cougarblight and Infection Potential risk levels are based on the
        principle that
      </p>

      <ul className="space-y-2 list-decimal list-inside">
        <li>
          a certain number of heat units must accumulate during bloom for a
          threshold level of inoculum to be reached;
        </li>
        <li>
          a wetting event is necessary after this point to wash the bacteria to
          their infection sites; and
        </li>
        <li>the average temperature is above 60F.</li>
      </ul>

      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <tbody>
                  <tr className="level-1">
                    <td className="px-6 py-4 text-sm font-bold sm:whitespace-nowrap">
                      Marginal or Low risk
                    </td>
                    <td className="px-6 py-4 text-sm ">
                      If none of these conditions is met during bloom, risk is{' '}
                      <span className="font-bold ">'Marginal'</span> or {' '}
                      <span className="font-bold ">'Low'</span> and bactericides
                      are not needed.
                    </td>
                  </tr>
                  <tr className="level-2">
                    <td className="px-6 py-4 text-sm font-bold sm:whitespace-nowrap">
                      Moderate risk
                    </td>
                    <td className="px-6 py-4 text-sm ">
                      Infection Potential EIP risk
                      is <span className="font-bold ">'Moderate'</span> and it
                      is advisable to watch the forecast closely for continuing
                      warm weather and rain.
                    </td>
                  </tr>
                  <tr className="level-3">
                    <td className="px-6 py-4 text-sm font-bold sm:whitespace-nowrap">
                      High risk
                    </td>
                    <td className="px-6 py-4 text-sm ">
                      If two conditions are met during bloom, risk is{' '}
                      <span className="font-bold ">'High'</span> and forecasted
                      wetting events should be carefully considered and a
                      bactericide applied just before (or after) a rain.
                    </td>
                  </tr>

                  <tr className="level-4">
                    <td className="px-6 py-4 text-sm font-bold sm:whitespace-nowrap">
                      Extreme or Infection risk
                    </td>
                    <td className="px-6 py-4 text-sm ">
                      If all three conditions are met, risk is{' '}
                      <span className="font-bold ">'Extreme'</span> or{' '}
                      <span className="font-bold ">'Infection'</span> and an
                      antibiotic should be applied just before (or after) a
                      rain.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
