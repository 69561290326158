import React from 'react'

export default function LateView() {
  return (
    <div>
      <p>
        The period of high risk of blossom blight infection (20% bloom to 80%
        petal fall) has passed. If bloom is still present in your orchard,
        recalculate blossom blight risk by entering a first blossom open date.
        1-2 weeks after petal fall, scout orchards for shoot blight, including
        rootstock sucker infections. Prune and remove infected shoots 12 inches
        below the infected margin during cool, dry weather.
      </p>
      <p>
        Pruning is particularly useful when blossom blight is well controlled
        and canker blight infections are thus the main source of inoculum for
        disease spread during the summer. Pruning can help limit disease spread,
        but will be most effective if practiced rigorously during the first few
        weeks after bloom; pruning will do little to slow disease spread if
        delayed until a large number of infections are visible.
      </p>

      <p>
        Routine use of antibiotics to prevent shoot blight spread during the
        summer is not effective or recommended. However, applications to protect
        new wounds immediately following a hailstorm can be very beneficial;
        check current recommendations.
      </p>
    </div>
  )
}
