import React from 'react'
import Early from './_earlyView'
import InSeason from './_inSeasonView'
import Late from './_lateView'
import Fall from './_fallView'

export default function ManagementGuide({ management, cycle }) {
  const ManagementContent = () => {
    if (management === 'early') return <Early></Early>
    if (management === 'inSeason') return <InSeason></InSeason>
    if (management === 'late') return <Late></Late>
    if (management === 'fall') return <Fall></Fall>
    return management
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-secondary-800">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200"
                  >
                    Cycle
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200"
                  >
                    Management
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white">
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    {cycle}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <ManagementContent></ManagementContent>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
