import GlobalStateContext from '../../context/globalStateContext'
import { useAuth0 } from '../../context/react-auth0-spa'
import React from 'react'
import Select from 'react-select'
import { useSnackbar } from 'react-simple-snackbar'

const customStylesDropdown = {
  option: (provided) => ({
    ...provided,
    textAlign: 'left',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 10,
  }),
  input: (provided) => ({
    ...provided,
    width: '100%',
    textAlign: 'left',
  }),
}

export default function Dropdown({
  modelId,
  id,
  dbKey,
  width,
  list,
  ariaLabel,
  placeholder,
  setUserItem,
  item,
  station,
}) {
  const { dateOfInterest } = React.useContext(GlobalStateContext)
  const { user, setUser } = useAuth0()
  const [openSnackbar] = useSnackbar()

  function handleChange(entry) {
    if (user) {
      let model = user.activeTools.find((model) => model.id === modelId)
      if (model) {
        const activeToolsUpdated = user.activeTools.filter(
          (model) => model.id !== modelId,
        )
        if (Object.keys(model[dbKey]).includes(`${dateOfInterest.year}`)) {
          model[dbKey][dateOfInterest.year][station.id] = entry
        } else {
          model[dbKey][dateOfInterest.year] = {
            [station.id]: entry,
          }
        }
        setUser({
          ...user,
          activeTools: [...activeToolsUpdated, model],
        })
      }
      openSnackbar('Database has been updated!')
    }
    setUserItem(list.find((d) => d.value === entry.value))
  }
  return (
    <div className={width}>
      <Select
        name={id}
        id={id}
        aria-label={ariaLabel}
        value={item ? list.find((d) => d.value === item.value) : null}
        placeholder={placeholder}
        onChange={(v) => handleChange(v)}
        options={list}
        styles={customStylesDropdown}
        theme={(theme) => ({
          ...theme,
          borderRadius: 10,
          colors: {
            ...theme.colors,
            primary25: '#edf2f7',
            primary50: '#86BFDF',
            primary: '#718096',
            // neutral20: "#718096",
          },
        })}
      ></Select>
    </div>
  )
}
