import React from 'react'

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Biggs, A. R., and Turechek, W. W. 2010. Fire blight of apples and pears:
        Epidemiological concepts comprising the Maryblyt forecasting program.
        Plant Health Progress. doi:10.1094/PHP-2010-0315-01-RS.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Smith, T.J. 2000. Cougarblight fire blight infection risk assessment
        model. Washington State University.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Steiner, P. W. 1988. Maryblyt: A predictive model for eastern apple fire
        blight. Phytopathology 78:1571.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Steiner, P. W. 1990. Predicting apple blossom infections by Erwinia
        amylovora using the Maryblyt model. Acta Hort. 273:139-148.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Steiner, P. W. 1990. Predicting canker, shoot and trauma blight phases
        of apple fire blight epidemics using the Maryblyt program. Acta Hort.
        273:149-158.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Turechek, W. W., and Biggs, A. R. 2015. Maryblyt v. 7.1 for Windows: An
        improved fire blight forecasting program for apples and pears. Plant
        Health Progress 16(1):16-22. doi:10.1094/PHP-RS-14-0046.
      </p>
    </>
  )
}
