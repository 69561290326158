import { format, getDayOfYear } from 'date-fns'
import { baskervilleEmin, calculateGdd, simpleAve } from '../../utils/helpers'

const timSmithTable = {
  22: 0,
  23: 0,
  24: 0,
  25: 0,
  26: 0,
  27: 0,
  28: 0,
  29: 0,
  30: 0,
  31: 0,
  32: 0,
  33: 0,
  34: 0,
  35: 0,
  36: 0,
  37: 0,
  38: 0,
  39: 0,
  40: 0,
  41: 0,
  42: 0,
  43: 0,
  44: 0,
  45: 0,
  46: 0,
  47: 0,
  48: 0,
  49: 0,
  50: 0,
  51: 0.05,
  52: 0.1,
  53: 0.16,
  54: 0.21,
  55: 0.23,
  56: 0.29,
  57: 0.33,
  58: 0.38,
  59: 0.44,
  60: 0.52,
  61: 0.62,
  62: 0.72,
  63: 0.95,
  64: 1.06,
  65: 1.16,
  66: 1.41,
  67: 2,
  68: 2.7,
  69: 3.3,
  70: 4,
  71: 4.9,
  72: 6.3,
  73: 7.95,
  74: 11,
  75: 14.1,
  76: 17,
  77: 20.3,
  78: 23.5,
  79: 27,
  80: 30,
  81: 33.2,
  82: 36,
  83: 38.9,
  84: 42.2,
  85: 46,
  86: 48.2,
  87: 50.2,
  88: 52,
  89: 52,
  90: 51.5,
  91: 50.5,
  92: 47.5,
  93: 40,
  94: 28,
  95: 10,
  96: 0,
  97: 0,
  98: 0,
  99: 0,
  100: 0,
  101: -10,
  102: -20,
  103: -30,
  104: -40,
  105: -50,
  106: -55,
  107: -60,
  108: -66,
  109: -74,
  110: -80,
}

export default function fireBlightLogic(
  data,
  dateOfInterest,
  orchardBlightHistory,
  firstBlossomDate,
  userResettedFirstBlossom,
  streptomycinDate,
  traumaEventDate,
  symptomOccurrenceDate,
) {
  const year = dateOfInterest.year
  const doy = dateOfInterest.dayOfYear
  const march1Doy = getDayOfYear(new Date(year, 2, 1))
  const june15Doy = getDayOfYear(new Date(year, 5, 15))
  const september15Doy = getDayOfYear(new Date(year, 8, 15))
  const october31Doy = getDayOfYear(new Date(year, 9, 31))
  const todayDoy = getDayOfYear(new Date())
  const tomorrowDoy = todayDoy + 1

  const dailyDataWithGdd43BE = calculateGdd(
    data.dailyData,
    43,
    0,
    baskervilleEmin,
  )
  const dailyDataWithGdd40 = calculateGdd(data.dailyData, 40, 0, simpleAve)

  let ciccioDoy = -1
  if (firstBlossomDate) {
    ciccioDoy = getDayOfYear(new Date(firstBlossomDate))
  }

  let streptomycinDateDoy = -1
  if (streptomycinDate) {
    streptomycinDateDoy = getDayOfYear(new Date(streptomycinDate))
  }
  if (streptomycinDate === null) streptomycinDateDoy = -1

  let degHrs4DaySum = 0
  let degHrsArr = []
  let startingDoy = -1
  let hasFirstBloomByAcc
  const dailyDataWithGdd = dailyDataWithGdd43BE.map((d, i) => {
    // calculate degree hour
    const temps = d.temp.filter((t) => t !== 'M')
    const degHrsSum = Math.round(
      temps
        .map((t) => {
          if (t < 60 || t > 105) return 0
          const temp = Math.round(t)
          return timSmithTable[temp]
        })
        .reduce((acc, val) => acc + val, 0),
    )

    const hasFirstBloom = d.gdd >= 230 ? true : false
    if (hasFirstBloom) {
      if (!hasFirstBloomByAcc) {
        hasFirstBloomByAcc = d.dayOfYear
        startingDoy = d.dayOfYear
      }
    }
    if (ciccioDoy !== -1) startingDoy = ciccioDoy
    // if (streptomycinDateDoy !== -1) startingDoy = streptomycinDateDoy

    degHrsArr.push(degHrsSum)
    if (d.dayOfYear === startingDoy + 1) degHrs4DaySum = degHrsSum

    if (d.dayOfYear === startingDoy + 2) {
      degHrs4DaySum = degHrsArr
        .slice(i - 1, i + 1)
        .reduce((acc, val) => acc + val, 0)
    }

    if (d.dayOfYear === startingDoy + 3) {
      degHrs4DaySum = degHrsArr
        .slice(i - 2, i + 1)
        .reduce((acc, val) => acc + val, 0)
    }

    if (d.dayOfYear > startingDoy + 4) {
      degHrs4DaySum = degHrsArr
        .slice(i - 3, i + 1)
        .reduce((acc, val) => acc + val, 0)
    }

    if (streptomycinDateDoy !== -1) {
      if (d.dayOfYear === streptomycinDateDoy + 2) degHrs4DaySum = degHrsSum
      if (d.dayOfYear === streptomycinDateDoy + 3) {
        degHrs4DaySum = degHrsArr
          .slice(i - 1, i + 1)
          .reduce((acc, val) => acc + val, 0)
      }
      if (d.dayOfYear === streptomycinDateDoy + 4) {
        degHrs4DaySum = degHrsArr
          .slice(i - 2, i + 1)
          .reduce((acc, val) => acc + val, 0)
      }
    }

    let cougarblightRisk = 'low'
    if (orchardBlightHistory.value === 0) {
      if (degHrs4DaySum < 300) cougarblightRisk = 'low'
      if (degHrs4DaySum >= 300 && degHrs4DaySum < 800) cougarblightRisk = 'high'
      if (degHrs4DaySum >= 800) cougarblightRisk = 'extreme'
    }

    if (orchardBlightHistory.value === 1) {
      if (degHrs4DaySum < 150) cougarblightRisk = 'low'
      if (degHrs4DaySum >= 150 && degHrs4DaySum < 350) cougarblightRisk = 'high'
      if (degHrs4DaySum >= 350) cougarblightRisk = 'extreme'
    }

    if (orchardBlightHistory.value === 2) {
      if (degHrs4DaySum < 80) cougarblightRisk = 'low'
      if (degHrs4DaySum >= 80 && degHrs4DaySum < 200) cougarblightRisk = 'high'
      if (degHrs4DaySum >= 200) cougarblightRisk = 'extreme'
    }

    // wetness events
    let prcpSum = d.prcp
      .filter((p) => p !== 'M' && p !== undefined && p > 0)
      .reduce((acc, val) => acc + val, 0)

    if (dateOfInterest.isCurrentYear) {
      if (d.dayOfYear === todayDoy) {
        const qpfs = d.qpf.filter(Boolean).reduce((acc, val) => acc + val, 0)
        prcpSum = Number((prcpSum + qpfs).toFixed(2))
      }
      if (d.dayOfYear === tomorrowDoy) {
        const qpfs = d.qpf.filter(Boolean).reduce((acc, val) => acc + val, 0)
        prcpSum = Number(qpfs.toFixed(2))
      }
    }
    const lwetHrs = d.lwet.filter((d) => d > 0).length
    const rhGT90 = d.rhum.filter((d) => d !== 'M' && d > 90).length
    const rhums = d.rhum.filter((d) => d !== 'M')
    const maxRh = Math.max(...rhums)
    const minRh = Math.min(...rhums)
    let dwptString = 'no'
    if (d.dwpt) {
      const dwpts = d.dwpt
        .map((m, j) => (d.temp[j] - m <= 3 && d.prcp[j] === 0 ? 1 : 0))
        .reduce((acc, val) => acc + val, 0)
      if (dwpts > 0) dwptString = 'yes'
    }
    const avgT = (d.maxt + d.mint) / 2

    let pop10pm = 0
    let pop10am = 0
    if (i > 0 && d.pop12) {
      pop10pm = dailyDataWithGdd43BE[i - 1].pop12[21]
      pop10am = d.pop12[9]
    }

    const dh65 = Number(
      temps
        .map((t) => (t > 65 ? t - 65 : 0))
        .reduce((acc, val) => acc + val, 0)
        .toFixed(1),
    )

    return {
      date: d.date,
      ms: d.ms,
      dayOfYear: d.dayOfYear,
      dwpt: d.dwpt,
      isForecast: d.isForecast,
      temp: d.temp,
      rhum: d.rhum,
      pop12: d.pop12,
      maxt: d.maxt,
      mint: d.mint,
      prcp: d.prcp,
      lwet: d.lwet,
      dd43BE: d.dd,
      gdd43BE: d.gdd,
      hasFirstBloom: d.gdd >= 230 ? true : false,
      hasPetalFall:
        d.gdd >= 578 ? true : d.dayOfYear >= june15Doy ? true : false,
      dd40: dailyDataWithGdd40[i].dd,
      dateDisplay: format(d.date, 'yyyy-MM-dd'),
      degHrs4DaySum,
      cougarblightRisk,
      prcpSum,
      lwetHrs,
      rhGT90,
      maxRh,
      minRh,
      dwptString,
      avgT,
      pop10pm,
      pop10am,
      dh65,
      eip: 0,
      infectionPotential: 'low',
    }
  })

  const selectedDate = dailyDataWithGdd.find(
    (d) => d.dateDisplay === dateOfInterest.server,
  )

  let firstBlossomOpen = null
  let firstBlossomByDDAccumulation = dailyDataWithGdd.find(
    (d) => d.hasFirstBloom,
  )

  if (firstBlossomByDDAccumulation) {
    if (doy < firstBlossomByDDAccumulation.dayOfYear)
      firstBlossomByDDAccumulation = null

    if (firstBlossomByDDAccumulation) {
      if (doy >= firstBlossomByDDAccumulation.dayOfYear) {
        firstBlossomOpen = { ...firstBlossomByDDAccumulation }
      }
    }
  }

  if (firstBlossomDate) {
    firstBlossomOpen = dailyDataWithGdd.find(
      (d) => d.dateDisplay === firstBlossomDate,
    )
  }

  if (userResettedFirstBlossom) {
    firstBlossomOpen = null
  }

  let petalFall = dailyDataWithGdd.find((d) => d.hasPetalFall)
  if (petalFall) {
    if (doy < petalFall.dayOfYear) petalFall = null
  }

  let view = 'dormant'
  if (firstBlossomOpen) {
    if (doy >= march1Doy && doy <= firstBlossomOpen.dayOfYear) {
      view = 'early'
    }
  }
  if (firstBlossomOpen && petalFall) {
    if (doy >= firstBlossomOpen.dayOfYear && doy <= petalFall.dayOfYear) {
      view = 'inSeason'
    }
  }
  if (petalFall) {
    if (doy >= petalFall.dayOfYear && doy <= september15Doy) {
      view = 'late'
      // firstBlossomOpen = null
    }
  }
  if (doy > june15Doy && doy <= october31Doy) {
    view = 'fall'
  }

  if (userResettedFirstBlossom) view = 'early'
  if (userResettedFirstBlossom === false) {
    if (firstBlossomDate) {
      view = 'inSeason'
    } else {
      if (doy >= june15Doy && doy <= september15Doy) {
        view = 'late'
        firstBlossomOpen = null
      } else {
        if (selectedDate.hasPetalFall) {
          view = 'late'
          firstBlossomOpen = null
        } else {
          if (selectedDate.hasFirstBloom) {
            view = 'inSeason'
          } else {
            view = 'early'
          }
        }
      }
    }
  }

  // if (view === 'late') firstBlossomOpen = null

  let streptomycin = null
  if (streptomycinDate) {
    streptomycin = dailyDataWithGdd.find(
      (d) => d.dateDisplay === streptomycinDate,
    )
  }

  ///////////////////////////////////////////////////////////////////////////
  let accDD40 = 0
  let startDate = null
  if (firstBlossomOpen) {
    startDate = dailyDataWithGdd[firstBlossomOpen.dayOfYear]
    let endIdx = dateOfInterest.dayOfYear + 5
    if (dateOfInterest.isCurrentYear === false) {
      endIdx = dailyDataWithGdd.length
    }

    for (let i = firstBlossomOpen.dayOfYear; i < endIdx; i++) {
      const day = dailyDataWithGdd[i]
      while (accDD40 >= 80) {
        accDD40 -= dailyDataWithGdd[startDate.dayOfYear].dd40
        startDate = { ...dailyDataWithGdd[startDate.dayOfYear] }
      }

      let accDh65 = 0
      let td = { ...startDate }

      while (td.dayOfYear <= day.dayOfYear) {
        let oneDayBeforeTd = { ...dailyDataWithGdd[td.dayOfYear - 2] }
        let twoDaysBeforeTd = { ...dailyDataWithGdd[td.dayOfYear - 3] }
        if (
          td.maxt <= 65 &&
          oneDayBeforeTd.maxt <= 65 &&
          twoDaysBeforeTd.maxt <= 65
        ) {
          startDate = { ...day }
          td = { ...day }
          oneDayBeforeTd = { ...dailyDataWithGdd[td.dayOfYear - 2] }
          twoDaysBeforeTd = { ...dailyDataWithGdd[td.dayOfYear - 3] }
          accDD40 = 0
          accDh65 = 0
        }
        accDh65 += td.dh65
        if (td.mint < 24) {
          accDh65 = 0
        } else if (td.mint < 33 && accDh65 < 400) {
          accDh65 = 0
        } else if (td.maxt <= 65 && accDh65 < 400) {
          if (oneDayBeforeTd.maxt <= 65 && twoDaysBeforeTd.maxt <= 65) {
            accDh65 = 0
          } else if (oneDayBeforeTd.maxt <= 65) {
            accDh65 *= 0.5
          } else {
            accDh65 *= 0.667
          }
        }
        td = { ...dailyDataWithGdd[td.dayOfYear] }
      }

      if (streptomycin !== null && streptomycin.dayOfYear === day.dayOfYear) {
        startDate = dailyDataWithGdd[streptomycin.dayOfYear] // day after strepto date
        accDD40 = 0
        accDh65 = 0
      } else {
        accDD40 += day.dd40
      }

      let riskEip = 0
      const eip = Math.round((accDh65 / 198) * 100)
      day.eip = eip
      if (eip > 100) riskEip++
      if (day.avgT > 60) riskEip++

      const yest = dailyDataWithGdd[day.dayOfYear - 2]
      if (
        day.prcpSum > 0 ||
        day.lwetHrs > 0 ||
        day.dwptString === 'yes' ||
        yest.prcpSum > 0.1
      ) {
        riskEip++
      }
      let infectionPotential = 'low'
      // if (riskEip === 0) infectionPotential = 'low'
      if (riskEip === 1) infectionPotential = 'moderate'
      if (riskEip === 2) infectionPotential = 'high'
      if (riskEip === 3) infectionPotential = 'infection'
      day.infectionPotential = infectionPotential
    }
  }
  ////////////////////////////////////////////////////////////////////////////

  // Results Table //////////////////////////////////////////////////////////
  let tableData = [...dailyDataWithGdd]
  if (dailyDataWithGdd.length >= 8) {
    tableData = dailyDataWithGdd.slice(
      selectedDate.dayOfYear - 3,
      selectedDate.dayOfYear + 5,
    )
  }

  const csvData = dailyDataWithGdd.map((d) => {
    return {
      date: d.dateDisplay,
      'Cougar Blight 4-Day DH': d.degHrs4DaySum,
      'Infection Potential EIP Value': d.eip,
    }
  })

  const csvDataWetnessEvents = dailyDataWithGdd.map((d) => {
    return {
      date: d.dateDisplay,
      'Rain Amount (in)': d.prcpSum.toFixed(2),
      Dew: d.dwptString,
      'Leaf Wetness (hrs)': d.lwetHrs,
      'Hrs > 90% RH': d.rhGT90,
      'RH max/min': `${d.maxRh}/${d.minRh}`,
      'Avg Temp (˚F)': d.avgT.toFixed(1),
    }
  })

  // Graph ///////////////////////////////////////////////////////
  const april1Doy = getDayOfYear(new Date(year, 3, 1))
  const graphData = dailyDataWithGdd
    .slice(april1Doy - 1, dateOfInterest.dayOfYear + 5)
    .map((d) => {
      return {
        dayOfYear: d.dayOfYear,
        dateDisplay: d.dateDisplay,
        prcpSum: d.prcpSum.toFixed(2),
        mint: d.mint,
        maxtMinusMint: (d.maxt - d.mint).toFixed(1),
        mintMaxt: `${d.mint} / ${d.maxt}`,
        degHrs4DaySum: d.degHrs4DaySum,
        cougarblightRisk: d.cougarblightRisk,
      }
    })

  // Shoot Blight ////////////////////////////////////////////////
  let trauma = null
  let symptoms = null
  if (traumaEventDate) {
    const dd = dailyDataWithGdd.find((d) => d.dateDisplay === traumaEventDate)
    let dailyDataWithGdd45BE = []
    if (dd) {
      trauma = dd
      dailyDataWithGdd45BE = calculateGdd(
        data.dailyData,
        55,
        dd.dayOfYear - 1,
        baskervilleEmin,
      ).map((d) => {
        return {
          date: d.date,
          dayOfYear: d.dayOfYear,
          dd: d.dd,
          gdd45BE: d.gdd,
          symptoms: d.gdd >= 90 ? true : false,
        }
      })
    }
    symptoms = dailyDataWithGdd45BE.find((d) => d.symptoms)
  }
  if (traumaEventDate === null) {
    trauma = null
    symptoms = null
  }

  // symptomOccurrenceDate //
  let symDate = null
  let infection = null
  if (symptomOccurrenceDate) {
    const dd = dailyDataWithGdd.find(
      (d) => d.dateDisplay === symptomOccurrenceDate,
    )
    let dailyDataWithGdd45BE = []
    if (dd) {
      symDate = dd
      const reversedData = [...data.dailyData].reverse()
      const index = reversedData.findIndex(
        (d) => d.dayOfYear === symDate.dayOfYear,
      )

      if (index !== -1) {
        dailyDataWithGdd45BE = calculateGdd(
          reversedData,
          55,
          index,
          baskervilleEmin,
        ).map((d) => {
          return {
            date: d.date,
            dayOfYear: d.dayOfYear,
            dd: d.dd,
            gdd45BE: d.gdd,
            infection: d.gdd >= 90 ? true : false,
          }
        })
      }
      infection = dailyDataWithGdd45BE.find((d) => d.infection)
    }
  }
  if (symptomOccurrenceDate === null) {
    symDate = null
    infection = null
  }

  return {
    view,
    selectedDate,
    firstBlossomOpen,
    tableData,
    csvData,
    csvDataWetnessEvents,
    graphData,
    trauma,
    symptoms,
    symDate,
    infection,
    streptomycin,
  }
}
