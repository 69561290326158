import React from 'react'

export default function EarlyView() {
  return (
    <div>
      <p>
        Learn how to identify fire blight cankers. Scout orchard blocks for
        active, oozing fire blight cankers. Determine and record each orchard
        blocks' fire blight history to use in the blossom blight tool, as shown:{' '}
        <br />
        --No fire blight in your neighborhood last year. <br />
        --Fire blight occurred in your neighborhood last year (default). <br />
        --Fire blight is now active in your neighborhood. <br />
        At silver tip apply a copper spray to clean up inoculum on tree
        surfaces. <br />
        At bloom, apples and pears become susceptible to blossom blight
        infections. <br />
        <a
          href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/Notes-on-first-blossom-open-biofix-20201221.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          first blossom open
        </a>{' '}
        begins the critical period for protection during bloom, which is when
        blossom blight risk is calculated.
      </p>
    </div>
  )
}
