import { format, getDayOfYear } from "date-fns";
import React from "react";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./fire-blight.json";
import Acknowledgments from "./_acknowledgements";
import CougarBlightGraph from "./_cougarBlightGraph";
import DateInput from "./_dateInput";
import DateInputForStrep from "./_dateInputStrep";
import Dropdown from "./_dropdown";
import ManagementGuide from "./_managementGuide";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";
import ShootBlightDate from "./_shootBlightDate";
import WeatherSummary from "./_weatherSummary";
import WetnessEventsTable from "./_wetnessEventsTable";

const visibleElementsArr = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );
// console.log(visibleElementsArr)

export default function FireBlight() {
  const h1Text = modelData.title;
  const { id, title, seasonStart, seasonEnd, elements } = modelData;
  const { userInputs, managementGuide } = elements;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  const [orchardBlightHistory, setOrchardBlightHistory] = React.useState({
    label: "Fire blight occurred in your neighborhood last year",
    value: 1,
  });
  const [firstBlossomDate, setFirstBlossomDate] = React.useState(null);
  const [userResettedFirstBlossom, setUserResettedFirstBlossom] =
    React.useState(false);
  const [streptomycinDate, setStreptomycinDate] = React.useState(null);
  const [traumaEventDate, setTraumaEventDate] = React.useState(null);
  const [symptomOccurrenceDate, setSymptomOccurrenceDate] =
    React.useState(null);

  let mData = null;
  let view = "dormant";
  if (data && isModelInSeason) {
    mData = modelLogic(
      data,
      dateOfInterest,
      orchardBlightHistory,
      firstBlossomDate,
      userResettedFirstBlossom,
      streptomycinDate,
      traumaEventDate,
      symptomOccurrenceDate
    );
    view = mData.view;
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const [showResultsTable2, setShowResultsTable2] = React.useState(true);
  const [cougarBlightGraph, setCougarBlightGraph] = React.useState(false);
  const [weatherSummaryGraph, setWeatherSummaryGraph] = React.useState(false);
  const isVisible = station && mData;

  const april1Doy = getDayOfYear(new Date(new Date().getFullYear(), 3, 1));

  const [visibleElements, setVisibleElements] = React.useState([]);

  React.useEffect(() => {
    setFirstBlossomDate(null);
    setStreptomycinDate(null);
  }, [station]);

  React.useEffect(() => {
    let filtered = [...visibleElementsArr];
    if (view === "inSeason") {
      filtered = [...visibleElementsArr];
    } else {
      filtered = [];
    }
    setVisibleElements(filtered);
  }, [dateOfInterest, view]);

  React.useEffect(() => {
    setUserResettedFirstBlossom(false);
    setFirstBlossomDate(null);
    setStreptomycinDate(null);
    if (dateOfInterest.dayOfYear < april1Doy) {
      setCougarBlightGraph(false);
      setWeatherSummaryGraph(false);
    }
  }, [dateOfInterest, april1Doy]);

  React.useEffect(() => {
    if (!user) return;

    if (Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === id);
      if (model) {
        if (
          Object.keys(model.orchardBlightHistory).includes(
            `${dateOfInterest.year}`
          ) &&
          Object.keys(model.orchardBlightHistory[dateOfInterest.year]).includes(
            station.id
          )
        ) {
          setOrchardBlightHistory(
            model.orchardBlightHistory[dateOfInterest.year][station.id]
          );
        } else {
          setOrchardBlightHistory({
            label: "Fire blight occurred in your neighborhood last year",
            value: 1,
          });
        }
        if (
          Object.keys(model.biofix1).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.biofix1[dateOfInterest.year]).includes(station.id)
        ) {
          setFirstBlossomDate(model.biofix1[dateOfInterest.year][station.id]);
        } else {
          setFirstBlossomDate(null);
        }
        if (
          Object.keys(model.biofix2).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.biofix2[dateOfInterest.year]).includes(station.id)
        ) {
          setStreptomycinDate(model.biofix2[dateOfInterest.year][station.id]);
        } else {
          setStreptomycinDate(null);
        }
      }
    }
  }, [station, id, user, dateOfInterest]);

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-lg lg:text-2xl"}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsTable2={showResultsTable2}
      setShowResultsTable2={setShowResultsTable2}
      showResultsChart={cougarBlightGraph}
      setShowResultsChart={setCougarBlightGraph}
      showResultsChart2={weatherSummaryGraph}
      setShowResultsChart2={setWeatherSummaryGraph}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {isModelInSeason && !isLoading && (
        <div className="px-6 py-6 mb-12 bg-white rounded-lg shadow-lg">
          <div className="flex flex-col justify-center w-full mx-auto text-gray-700">
            <div className="items-center justify-center sm:flex">
              <div className="flex items-center mb-3">
                <span className="mr-2 font-bold">Orchard Blight History:</span>
                <Dropdown
                  modelId={id}
                  id="orchardBlightHistory"
                  dbKey="orchardBlightHistory"
                  width="w-96"
                  placeholder="Select Orchard Blight History"
                  ariaLabel="Orchard Blight History"
                  list={userInputs.firstInput.options}
                  item={orchardBlightHistory}
                  setUserItem={setOrchardBlightHistory}
                  station={station}
                ></Dropdown>
              </div>
            </div>
            {mData && mData.firstBlossomOpen ? (
              <p className="text-sm">{userInputs.firstInput.message1}</p>
            ) : (
              <p className="text-sm">{userInputs.firstInput.message2}</p>
            )}
          </div>
        </div>
      )}

      {isModelInSeason && !isLoading && (
        <div className="px-6 py-6 mb-12 bg-white rounded-lg shadow-lg">
          <>
            <div className="flex items-center justify-center py-3">
              <DateInput
                modelId={id}
                id={"biofix1"}
                dbKey="biofix1"
                minDate={new Date(`${dateOfInterest.year}-${seasonStart}`)}
                maxDate={dateOfInterest.date}
                biofix={mData && mData.firstBlossomOpen}
                setUserResettedFirstBlossom={setUserResettedFirstBlossom}
                userBiofix={firstBlossomDate}
                setUserBiofix={setFirstBlossomDate}
                label={elements.userInputs.secondInput.label}
                modelName={title}
                tooltip={`Click if bloom has not occurred`}
                station={station}
              ></DateInput>
            </div>
            {mData &&
              mData.firstBlossomOpen &&
              (mData.view === "early" || mData.view === "inSeason") && (
                <p className="text-sm">
                  The{" "}
                  <a
                    href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/Notes-on-first-blossom-open-biofix-20201221.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    first blossom open
                  </a>{" "}
                  above is estimated based on degree day accumulations. Enter
                  the actual first blossom open date for your orchard block of
                  interest and the tool will calculate the protection period
                  during bloom more accurately.
                </p>
              )}
            {mData &&
              !mData.firstBlossomOpen &&
              (mData.view === "early" || mData.view === "inSeason") && (
                <p className="text-sm ">
                  {elements.userInputs.secondInput.message2}
                </p>
              )}
            {mData && mData.view === "late" && (
              <p className="text-sm ">
                Degree day accumulations estimate that the bloom period is over.
                If bloom is still present, enter the first blossom open date for
                the orchard block of interest to start the blossom blight tool.
              </p>
            )}

            {mData && mData.firstBlossomOpen && (
              <div className="flex items-center justify-center">
                Accumulated degree days (base 43°F BE) through{" "}
                {mData.selectedDate.dateDisplay}:{" "}
                <span className="ml-1 font-extrabold">
                  {mData.selectedDate.gdd43BE}
                </span>
              </div>
            )}
          </>
        </div>
      )}

      {isVisible && mData.view === "inSeason" && (
        <div className="px-6 py-6 mb-12 bg-white rounded-lg shadow-lg">
          <>
            <div className="flex items-center justify-center py-3">
              <DateInputForStrep
                modelId={id}
                id={"biofix2"}
                dbKey="biofix2"
                minDate={
                  firstBlossomDate
                    ? new Date(firstBlossomDate)
                    : new Date(`${dateOfInterest.year}-${seasonStart}`)
                }
                biofix={mData && mData.streptomycin}
                userBiofix={streptomycinDate}
                setUserBiofix={setStreptomycinDate}
                label={elements.userInputs.thirdInput.label}
                modelName={title}
                tooltip={`Reset date to default value`}
                station={station}
              ></DateInputForStrep>
            </div>

            <p className="text-sm ">{elements.userInputs.thirdInput.message}</p>
          </>
        </div>
      )}

      {isVisible && mData.view === "inSeason" && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            tableData={mData.tableData}
            csvData={mData.csvData}
            dateOfInterest={dateOfInterest}
            firstBlossomOpen={mData.firstBlossomOpen}
            streptomycin={mData.streptomycin}
          ></ResultsTable>
        </div>
      )}

      {isVisible && mData.view === "inSeason" && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <WetnessEventsTable
            station={station}
            tableData={mData.tableData}
            csvData={mData.csvDataWetnessEvents}
            dateOfInterest={dateOfInterest}
          ></WetnessEventsTable>
        </div>
      )}

      {isVisible && (
        <div className={`mb-16 sm:mb-18 md:mb-20`}>
          <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
            Management Guide
          </h2>
          <ManagementGuide
            management={mData.view}
            cycle={managementGuide[mData.view]}
          ></ManagementGuide>
        </div>
      )}

      {!isModelInSeason && !isLoading && (
        <div className={`mb-16 sm:mb-18 md:mb-20`}>
          <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
            Management Guide
          </h2>
          <ManagementGuide
            management={managementGuide.outOfSeasonMsg.management}
            cycle={managementGuide.outOfSeasonMsg.cycle}
          ></ManagementGuide>
        </div>
      )}

      {isVisible &&
        mData.view === "inSeason" &&
        cougarBlightGraph &&
        dateOfInterest.dayOfYear >= april1Doy && (
          <div className={`mb-16 sm:mb-18 md:mb-20`}>
            <CougarBlightGraph
              title="Daily Cougarblight Risk"
              data={mData.graphData}
              biofix1={mData.firstBlossomOpen}
              biofix2={mData.streptomycin}
            ></CougarBlightGraph>
          </div>
        )}

      {isVisible &&
        mData.view === "inSeason" &&
        weatherSummaryGraph &&
        dateOfInterest.dayOfYear >= april1Doy && (
          <div className={`mb-16 sm:mb-18 md:mb-20`}>
            <WeatherSummary
              title="Daily Weather Summary"
              data={mData.graphData}
              biofix1={mData.firstBlossomOpen}
              biofix2={mData.streptomycin}
            ></WeatherSummary>
          </div>
        )}

      {isVisible && (mData.view === "late" || mData.view === "fall") && (
        <div className="px-6 py-6 mb-12 bg-white rounded-lg shadow-lg">
          <>
            <h2 className="mb-2 font-bold text-center">
              Scouting for Shoot Blight associated with a Trauma Event
            </h2>
            <p className="mx-auto text-sm sm:max-w-2xl">
              {elements.userInputs.fourthInput.message}
            </p>

            <div className="flex items-center justify-center py-3 mb-4">
              <ShootBlightDate
                id={"traumaEventDate"}
                minDate={new Date(`${dateOfInterest.year}-${seasonStart}`)}
                maxDate={dateOfInterest.date}
                biofix={mData.trauma}
                setBiofix={setTraumaEventDate}
                label={elements.userInputs.fourthInput.label}
                tooltip={`Reset date to default value`}
              ></ShootBlightDate>
            </div>

            {mData.symptoms && (
              <h3 className="text-center">
                Check for trauma blight symptoms starting on{" "}
                {format(mData.symptoms.date, "MMMM do")}
              </h3>
            )}
            {mData.symptoms && (
              <p className="font-semibold text-center">
                Degree Days (base 55 BE) {format(mData.trauma.date, "M/d")}{" "}
                through {format(mData.symptoms.date, "M/d")}:{" "}
                <span className="font-bold">{mData.symptoms.gdd45BE}</span>
              </p>
            )}
          </>
        </div>
      )}

      {isVisible && (mData.view === "late" || mData.view === "fall") && (
        <div className="px-6 py-6 mb-12 bg-white rounded-lg shadow-lg">
          <>
            <h2 className="mb-2 font-bold text-center">
              When did the Shoot Blight Infection occur?
            </h2>
            <p className="mx-auto text-sm sm:max-w-2xl">
              {elements.userInputs.fifthInput.message}
            </p>

            <div className="flex items-center justify-center py-3 mb-4">
              <ShootBlightDate
                id={"infectionDate"}
                minDate={new Date(`${dateOfInterest.year}-${seasonStart}`)}
                maxDate={dateOfInterest.date}
                biofix={mData.symDate}
                setBiofix={setSymptomOccurrenceDate}
                label={elements.userInputs.fifthInput.label}
                tooltip={`Reset date to default value`}
              ></ShootBlightDate>
            </div>

            {mData.symDate && (
              <h3 className="text-center">
                Approximate infection date occurred on{" "}
                {format(mData.infection.date, "MMMM do")}
              </h3>
            )}
            {mData.symDate && (
              <p className="font-semibold text-center">
                Degree Days (base 55 BE) {format(mData.infection.date, "M/d")}{" "}
                through {format(mData.symDate.date, "M/d")}:{" "}
                <span className="font-bold">{mData.infection.gdd45BE}</span>
              </p>
            )}
          </>
        </div>
      )}
    </ModelLayout>
  );
}
