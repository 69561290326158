import React from 'react'

export default function FallView() {
  return (
    <div>
      <p>
        Scout orchards for shoot blight and rootstock blight. Prune and remove
        infected shoots and limbs 12 inches below the infected margin during
        cool, dry weather. Routine use of antibiotics to prevent shoot blight
        spread during the summer is not effective or recommended. However,
        applications to protect new wounds immediately following a hailstorm can
        be very beneficial; check current recommendations. Learn how to identify
        fire blight cankers. Scout orchards for active, oozing fire blight
        cankers.
      </p>
    </div>
  )
}
