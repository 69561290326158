import { format } from 'date-fns'
import React from 'react'
import { CSVLink } from 'react-csv'

const rowBg = (riskLevel) => {
  if (riskLevel === 'low') return 'level-1'
  if (riskLevel === 'caution') return 'level-2'
  if (riskLevel === 'high') return 'level-3'
  if (riskLevel === 'extreme') return 'level-4'
}

const eipBg = (ip) => {
  if (ip === 'low') return 'level-1'
  if (ip === 'moderate') return 'level-2'
  if (ip === 'high') return 'level-3'
  if (ip === 'infection') return 'level-4'
}

export default function ResultsTable({
  station,
  tableData,
  csvData,
  dateOfInterest,
  firstBlossomOpen,
  streptomycin,
}) {
  if (!tableData) return null
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          Results Table
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`Results Table.csv`}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs sm:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forecast Details
            </a>
          </span>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-r border-gray-200 bg-secondary-800">
                    Date <small>({dateOfInterest.year})</small>
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    <span className="text-sm">Cougar Blight V8 Daily TRV</span>
                    <span className="block my-2 sm:w-4/5 sm:mx-auto">
                      <span className=" block mb-1 font-bold tracking-wider text-white text-xs">
                        Risk Levels:
                      </span>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-1 space-y-1 sm:space-y-0 text-center">
                        <span className="cell level-1 lg:text-xs">Marginal</span>
                        <span className="cell level-3 lg:text-xs">High</span>
                        <span className="cell level-4 lg:text-xs">Extreme</span>
                      </div>
                    </span>
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    <span className="text-sm">
                      Infection Potential EIP value
                    </span>
                    <span className="block my-2 sm:w-4/5 sm:mx-auto">
                      <span className="block mb-1 font-bold tracking-wider text-white text-xs">
                        Risk Levels:
                      </span>
                      <div className="sm:grid sm:grid-cols-4 sm:gap-1 space-y-1 sm:space-y-0">
                        <span className="cell level-1 lg:text-xs">Low</span>
                        <span className="cell level-2 lg:text-xs">
                          Moderate
                        </span>
                        <span className="cell level-3 lg:text-xs">High</span>
                        <span className="cell level-4 lg:text-xs">
                          Infection
                        </span>
                      </div>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.map((day, i) => {
                  return (
                    <tr
                      key={day.ms}
                      className={
                        i === 2 ? `font-extrabold text-center` : `text-center`
                      }
                    >
                      <td className={`td h-14`}>
                        <span className={`flex flex-col items-center cell`}>
                          {format(day.date, 'LLLL d')}
                          <small className="font-bold text-gray-500">
                            {day.isForecast ? 'Forecast' : ''}
                          </small>
                        </span>
                      </td>

                      {(firstBlossomOpen &&
                        firstBlossomOpen.dayOfYear >= day.dayOfYear) ||
                      (streptomycin &&
                        streptomycin.dayOfYear === day.dayOfYear) ? (
                        <td className={`td`}>
                          <span className="cell">-</span>
                        </td>
                      ) : (
                        <td className={`td`}>
                          <span
                            className={`cell ${rowBg(day.cougarblightRisk)}`}
                          >
                            {(firstBlossomOpen &&
                              (day.dayOfYear ===
                                firstBlossomOpen.dayOfYear + 1 ||
                                day.dayOfYear ===
                                  firstBlossomOpen.dayOfYear + 2 ||
                                day.dayOfYear ===
                                  firstBlossomOpen.dayOfYear + 3)) ||
                            (streptomycin &&
                              (day.dayOfYear === streptomycin.dayOfYear + 1 ||
                                day.dayOfYear === streptomycin.dayOfYear + 2 ||
                                day.dayOfYear === streptomycin.dayOfYear + 3))
                              ? `${day.degHrs4DaySum}*`
                              : day.degHrs4DaySum}
                          </span>
                        </td>
                      )}

                      {firstBlossomOpen &&
                      firstBlossomOpen.dayOfYear >= day.dayOfYear ? (
                        <td className={`td`}>
                          <span className={`cell`}>-</span>
                        </td>
                      ) : (
                        <td className={`td`}>
                          <span
                            className={`cell ${eipBg(day.infectionPotential)}`}
                          >
                            {day.eip.toFixed(0)}
                          </span>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <p className="text-xs px-4">
              * Indicates incomplete accumulation of the 4-day DH total. The DH
              value may reach "Caution", "High" or "Extreme" levels before
              spanning the 4-day accumulation cut-off time of Cougarblight.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
