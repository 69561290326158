import domtoimage from 'dom-to-image'
import { saveAs } from 'file-saver'
import React from 'react'
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ReferenceLine,
  Cell,
} from 'recharts'

import tailwindConfig from '../../../tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'
const { theme } = resolveConfig(tailwindConfig)

const CustomXLabel = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-15)"
      >
        {payload.value}
      </text>
    </g>
  )
}

const CustomYLabel = (props) => {
  const { x, y, payload, unit } = props
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={5}
        dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
      >
        {payload.value}
        {unit}
      </text>
    </g>
  )
}

const CustomizedReferenceLineLabel = (props) => {
  const { date, viewBox } = props
  return (
    <g>
      <text
        x={viewBox.x}
        y={viewBox.y}
        dy={-5}
        // dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#1F2937"
      >
        {date}
      </text>
    </g>
  )
}

const renderTooltip = ({ payload, param }) => {
  if (typeof payload === 'undefined') return null
  if (!payload) return null
  if (payload.length === 0) return null
  return payload.map((entry, i) => {
    if (i === 0) {
      return (
        <div
          key={i}
          className="flex flex-col px-4 py-3 rounded bg-gray-50 opacity-90"
        >
          <div>
            <span className="mr-1 text-xs font-semibold">Date:</span>
            <span className="text-xs">{entry.payload.dateDisplay}</span>
          </div>
          <div className="mt-2">
            <span className="mr-1 text-xs font-semibold">{param.name}:</span>
            <span className="text-xs">{entry.payload[param.key]}</span>
          </div>
        </div>
      )
    } else {
      return null
    }
  })
}

function barColor(entry) {
  const { cougarblightRisk } = entry
  if (cougarblightRisk === 'low') return theme.colors.green['500']
  if (cougarblightRisk === 'caution') return theme.colors.yellow['300']
  if (cougarblightRisk === 'high') return theme.colors.orange['500']
  if (cougarblightRisk === 'extreme') return theme.colors.red['800']
}

export default function CougarBlightGraph({ title, data, biofix1, biofix2 }) {
  function downloadPNG() {
    domtoimage
      .toBlob(document.getElementById('CougarBlightGraph'))
      .then((blob) => saveAs(blob, `${title} Chart.png`))
  }

  if (data === null) return null

  return (
    <div className="w-full" aria-hidden="true">
      <div className="flex items-center justify-between mb-6">
        <h2 className="inline-block text-lg font-semibold text-gray-600 sm:text-xl md:text-2xl">
          {title}
        </h2>

        <div className="flex items-center justify-center rounded-md">
          <button
            type="button"
            aria-label="download graph in png format"
            onClick={downloadPNG}
            className="inline-flex items-center p-2 text-sm font-bold leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>
            <span className="hidden mr-1 sm:inline-block">Download </span>{' '}
            <span className="text-white">PNG</span>
          </button>
        </div>
      </div>

      <div id="CougarBlightGraph">
        <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
          <div className="flex items-center"></div>

          <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
            <span className="mb-2 mr-3 font-bold tracking-wider text-gray-700 lg:mb-0">
              Risk Level:
            </span>
            <div className="grid grid-cols-4 gap-1">
              <span className="cell level-1">Low</span>
              <span className="cell level-2">Caution</span>
              <span className="cell level-3">High</span>
              <span className="cell level-4">Extreme</span>
            </div>
          </div>
        </div>

        <ResponsiveContainer width="100%" height={250} id="CougarBlight">
          <ComposedChart
            data={data}
            margin={{ top: 20, right: 30, left: -30, bottom: 20 }}
            className="bg-white border-b border-gray-200 shadow sm:rounded-lg"
          >
            <XAxis
              dataKey="dateDisplay"
              interval={'preserveStartEnd'}
              axisLine={true}
              tick={<CustomXLabel />}
            ></XAxis>
            <YAxis
              width={110}
              dataKey="degHrs4DaySum"
              tick={<CustomYLabel />}
              label={{
                value: '4-Day Degree Hour Sum',
                angle: -90,
                position: 'center',
                offset: 0,
              }}
            />
            <Tooltip
              content={renderTooltip}
              param={{ name: '4-Day Degree Hour Sum', key: 'degHrs4DaySum' }}
            />

            <Bar dataKey="degHrs4DaySum">
              {data.map((entry, index) => {
                if (biofix1 && entry.dayOfYear < biofix1.dayOfYear) {
                  return <Cell key={`cell-${index}`} fill={'#FFFFFF'} />
                } else {
                  return <Cell key={`cell-${index}`} fill={barColor(entry)} />
                }
              })}
            </Bar>
            {biofix1 && !biofix2 && (
              <ReferenceLine
                x={biofix1.dateDisplay}
                stroke="#1F2937"
                strokeWidth={1}
                isFront={true}
                label={<CustomizedReferenceLineLabel date={'First Blossom'} />}
              />
            )}
            {biofix2 && (
              <ReferenceLine
                x={biofix2.dateDisplay}
                stroke="#1F2937"
                strokeWidth={1}
                isFront={true}
                label={<CustomizedReferenceLineLabel date={'Streptomycin'} />}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
