import GlobalStateContext from '../../context/globalStateContext'
import { setDate } from '../../utils/helpers'
import CustomInput from '../../components/models/customInput'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ReactTooltip from 'react-tooltip'

export default function BiofixDateInput({
  id,
  minDate,
  maxDate,
  biofix,
  setBiofix,
  label,
  tooltip,
}) {
  const { dateOfInterest } = React.useContext(GlobalStateContext)
  const [dd, setDD] = React.useState(
    biofix && dateOfInterest.dayOfYear >= biofix.dayOfYear
      ? biofix.date
      : dateOfInterest.date,
  )

  function handleChange(d) {
    const ddd = setDate(d)
    setDD(d)
    setBiofix(ddd.server)
  }

  function resetBiofix() {
    setBiofix(null)
  }

  React.useEffect(() => {
    if (!biofix) {
      setDD(undefined)
    } else {
      if (dateOfInterest.dayOfYear >= biofix.dayOfYear) {
        setDD(biofix.date)
      }
    }
  }, [biofix, dateOfInterest])

  return (
    <div className="">
      <label
        htmlFor={id}
        className="block mb-2 font-semibold leading-5 text-left text-gray-700 "
      >
        {label}
      </label>
      <div className="flex items-center mt-2">
        <DatePicker
          className="mr-1"
          id={id}
          selected={dd}
          onChange={(d) => handleChange(d)}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText="click to enter a date"
          fixedHeight
          // filterDate={isDayMissing}
          // renderDayContents={renderDayContents}
          customInput={
            <CustomInput
              placeholderText="click to enter a date"
              isDisabled={false}
            />
          }
        />
        {dd && (
          <>
            <button
              type="button"
              aria-label={`Clear biofix`}
              data-tip={tooltip}
              // data-event="click"
              data-for="biofix"
              onClick={resetBiofix}
              className="inline-flex items-center p-2 ml-1 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              clear
            </button>
            <ReactTooltip
              id="biofix"
              globalEventOff="click"
              // type="info"
              effect="solid"
              // aria-haspopup="true"
            />
          </>
        )}
      </div>
    </div>
  )
}
